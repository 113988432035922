import { render, staticRenderFns } from "./wheeledHighL7fire.vue?vue&type=template&id=bdbd21da&scoped=true&"
import script from "./wheeledHighL7fire.vue?vue&type=script&lang=js&"
export * from "./wheeledHighL7fire.vue?vue&type=script&lang=js&"
import style0 from "./wheeledHighL7fire.vue?vue&type=style&index=0&id=bdbd21da&prod&scoped=true&rel=stylesheet%2Fstylus&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bdbd21da",
  null
  
)

export default component.exports